import React from "react";
export const ColorSheet = [
  {
    name: "Dracula Color Scheme",
    commands: [
      {
        name: "#282a36",
        color: true,
        tooltip: "Background",
      },
      {
        name: "#44475a",
        color: true,
        tooltip: "Current Line",
      },
      {
        name: "#f8f8f2",
        color: true,
        tooltip: "Foreground",
      },
      {
        name: "#6272a4",
        color: true,
        tooltip: "Comment",
      },
      {
        name: "#8be9fd",
        color: true,
        tooltip: "Cyan",
      },
      {
        name: "#50fa7b",
        color: true,
        tooltip: "Green",
      },
      {
        name: "#ffb86c",
        color: true,
        tooltip: "Orange",
      },
      {
        name: "#ff79c6",
        color: true,
        tooltip: "Pink",
      },
      {
        name: "#bd93f9",
        color: true,
        tooltip: "Purple",
      },
      {
        name: "#ff5555",
        color: true,
        tooltip: "Red",
      },
      {
        name: "#f1fa8c",
        color: true,
        tooltip: "Yellow",
      },
    ],
  },
   {
    name: "Nord Color Scheme",
    commands: [
      {
        name: "#1e3440",
        color: true,
        tooltip: "Background",
      },
      {
        name: "#2b4252",
        color: true,
        tooltip: "Current Line",
      },
      {
        name: "#eceff3",
        color: true,
        tooltip: "Foreground",
      },
      {
        name: "#3c566a",
        color: true,
        tooltip: "Comment",
      },
      {
        name: "#87c0d0",
        color: true,
        tooltip: "Cyan",
      },
      {
        name: "#7fbcbb",
        color: true,
        tooltip: "Green",
      },
      {
        name: "#d8769",
        color: true,
        tooltip: "Orange",
      },
      {
        name: "#b47ead",
        color: true,
        tooltip: "Pink",
      },
      {
        name: "#4e81ac",
        color: true,
        tooltip: "Purple",
      },
      {
        name: "#bf615a",
        color: true,
        tooltip: "Red",
      },
      {
        name: "#ebcb7b",
        color: true,
        tooltip: "Yellow",
      },
    ],
  },
  {
    name: "One Dark Pro Color Scheme",
    commands: [
      {
        name: "#281c34",
        color: true,
        tooltip: "Background",
      },
      {
        name: "#1c313a",
        color: true,
        tooltip: "Current Line",
      },
      {
        name: "#abb1bf",
        color: true,
        tooltip: "Foreground",
      },
      {
        name: "#4c6370",
        color: true,
        tooltip: "Comment",
      },
      {
        name: "#55b6c2",
        color: true,
        tooltip: "Cyan",
      },
      {
        name: "#97c379",
        color: true,
        tooltip: "Green",
      },
      {
        name: "#e05c75",
        color: true,
        tooltip: "Orange",
      },
      {
        name: "#c677dd",
        color: true,
        tooltip: "Pink",
      },
      {
        name: "#60afef",
        color: true,
        tooltip: "Purple",
      },
],
  },
  {
    name: "Material Theme Palenight Color Scheme",
    commands: [
      {
        name: "#291d3e",
        color: true,
        tooltip: "Background",
      },
      {
        name: "#444266",
        color: true,
        tooltip: "Current Line",
      },
      {
        name: "#f2f3f3",
        color: true,
        tooltip: "Foreground",
      },
      {
        name: "#675e95",
        color: true,
        tooltip: "Comment",
      },
      {
        name: "#94e6cb",
        color: true,
        tooltip: "Cyan",
      },
      {
        name: "#c2e88d",
        color: true,
        tooltip: "Green",
      },
      {
        name: "#ffcb5b",
        color: true,
        tooltip: "Orange",
      },
      {
        name: "#f77c6c",
        color: true,
        tooltip: "Pink",
      },
      {
        name: "#ff5369",
        color: true,
        tooltip: "Red",
      },
      {
        name: "#c791ea",
        color: true,
        tooltip: "Purple",
      },
    ],
  },
  {
    name: "Material Theme Ocean Color Scheme",
    commands: [
      {
        name: "#1b303b",
        color: true,
        tooltip: "Background",
      },
      {
        name: "#342d46",
        color: true,
        tooltip: "Current Line",
      },
      {
        name: "#c1c5ce",
        color: true,
        tooltip: "Foreground",
      },
      {
        name: "#65736e",
        color: true,
        tooltip: "Comment",
      },
      {
        name: "#7fa1b3",
        color: true,
        tooltip: "Cyan",
      },
      {
        name: "#a2be8c",
        color: true,
        tooltip: "Green",
      },
      {
        name: "#ebcb7b",
        color: true,
        tooltip: "Orange",
      },
      {
        name: "#ab7966",
        color: true,
        tooltip: "Pink",
      },
      {
        name: "#ab7966",
        color: true,
        tooltip: "Purple",
      },
      {
        name: "#bf615a",
        color: true,
        tooltip: "Red",
      },
      {
        name: "#d8769",
        color: true,
        tooltip: "Yellow",
      },
    ],
  },

];
