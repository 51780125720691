import { useState } from "react";
//import './Tooltip.css'; // Make sure to create this CSS file

export const Tooltip = ({
  children,
  text,
}: {
  children: any;
  text: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {isVisible && <div className="tooltip-box">{text}</div>}
      {children}
    </div>
  );
};
