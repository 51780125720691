import { Tooltip } from "../components/toolTip";

export const SqlSheet = [
  {
    name: "Sql",
    commands: [
      {
        name: "SELECT",
        tooltip: "This command selects data from a database.",
      },
      {
        name: "UPDATE",
        tooltip: "This command updates data in a database.",
      },
      {
        name: "DELETE",
        tooltip: "This command deletes data from a database.",
      },
      {
        name: "INSERT",
        tooltip: "This command inserts data into a database.",
      },
      {
        name: "CREATE",
        tooltip: "This command creates a new database or table.",
      },
      {
        name: "ALTER",
        tooltip: "This command alters a database or table.",
      },
      {
        name: "DROP",
        tooltip: "This command drops a database or table.",
      },
      {
        name: "TRUNCATE",
        tooltip: "This command truncates a table.",
      },
      {
        name: "JOIN",
        tooltip: "This command joins two or more tables.",
      },
      {
        name: "UNION",
        tooltip: "This command combines the result of two or more SELECT statements.",
      },
      {
        name: "GROUP BY",
        tooltip: "This command groups rows that have the same values into summary rows.",
      },
      {
        name: "HAVING",
        tooltip: "This command filters groups based on a specified condition.",
      },
      {
        name: "ORDER BY",
        tooltip: "This command sorts the result set in ascending or descending order.",
      },
      {
        name: "COUNT",
        tooltip: "This command counts the number of rows in a table.",
      },
      {
        name: "SUM",
        tooltip: "This command calculates the sum of a set of values.",
      },
      {
        name: "AVG",
        tooltip: "This command calculates the average of a set of values.",
      },
      {
        name: "MAX",
        tooltip: "This command returns the maximum value in a set of values.",
      },
      {
        name: "MIN",
        tooltip: "This command returns the minimum value in a set of values.",
      },
      {
        name: "LIKE",
        tooltip: "This command searches for a specified pattern in a column.",
      },
      {
        name: "IN",
        tooltip: "This command specifies multiple values for a column.",
      },
      {
        name: "BETWEEN",
        tooltip: "This command selects values within a given range.",
      },
      {
        name: "AS",
        tooltip: "This command assigns an alias to a column or table.",
      },
      {
        name: "NULL",
        tooltip: "This command represents a missing or unknown value.",
      },
      {
        name: "IS NULL",
        tooltip: "This command checks if a value is NULL.",
      },
      {
        name: "IS NOT NULL",
        tooltip: "This command checks if a value is not NULL.",
      },
      {
        name: "AND",
        tooltip: "This command combines multiple conditions.",
      },
      {
        name: "OR",
        tooltip: "This command specifies multiple conditions.",
      },
      {
        name: "NOT",
        tooltip: "This command negates a condition.",
      },
      {
        name: "ORDER BY",
        tooltip: "This command sorts the result set in ascending or descending order.",
      },
      {
        name: "LIMIT",
        tooltip: "This command limits the number of rows returned.",
      },
      {
        name: "OFFSET",
        tooltip: "This command specifies the starting row for the result set.",
      },
      {
        name: "FETCH",
        tooltip: "This command fetches a specified number of rows.",
      },
      {
        name: "TOP",
        tooltip: "This command selects the top number of rows.",
      },
      {
        name: "DISTINCT",
        tooltip: "This command returns unique values in a column.",
      },
      {
        name: "CASE",
        tooltip: "This command specifies a condition and returns a value when the condition is met.",
      },
      {
        name: "WHEN",
        tooltip: "This command specifies a condition in a CASE statement.",
      },
      {
        name: "THEN",
        tooltip: "This command specifies the value to return in a CASE statement.",
      },
      {
        name: "ELSE",
        tooltip: "This command specifies the value to return if no condition is met in a CASE statement.",
      },
      {
        name: "END",
        tooltip: "This command ends a CASE statement.",
      },
      {
        name: "PRIMARY KEY",
        tooltip: "This command uniquely identifies each record in a table.",
      },
      {
        name: "FOREIGN KEY",
        tooltip: "This command links two tables together.",
      },
      {
        name: "UNIQUE",
        tooltip: "This command ensures that all values in a column are different.",
      },
      {
        name: "CHECK",
        tooltip: "This command ensures that all values in a column meet a specified condition.",
      },
      {
        name: "DEFAULT",
        tooltip: "This command specifies a default value for a column.",
      },
    ],
  },
];