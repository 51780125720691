import { Tooltip } from "../components/toolTip";

export const GitSheet = [
  {
    name: "Git",
    commands: [
      {
        name: "git init",
        tooltip: "This command is used to start a new repository.",
      },
      {
        name: "git add",
        tooltip: "This command adds a file to the staging area.",
      },
    ],
  },
  {
    name: "Basic Snapshotting",
    commands: [
      {
        name: "git add",
        tooltip: "This command adds a file to the staging area.",
      },
      {
        name: "git status",
        tooltip: "This command lists all the files that have to be committed.",
      },
      {
        name: "git diff",
        tooltip: "This command shows the file differences which are not yet staged.",
      },
      {
        name: "git commit",
        tooltip: "This command records or snapshots the file permanently in the version history.",
      },
      {
        name: "git notes",
        tooltip: "This command adds or inspects object notes.",
      },
      {
        name: "git restore",
        tooltip: "This command restores working tree files.",
      },
      {
        name: "git reset",
        tooltip: "This command resets the current HEAD to the specified state.",
      },
      {
        name: "git mv",
        tooltip: "This command moves or renames a file, a directory, or a symlink.",
      },
    ],
  },
  {
    name: "Branching and Merging",
    commands: [
      {
        name: "git branch",
        tooltip: "This command lists, creates, or deletes branches.",
      },
      {
        name: "git checkout",
        tooltip: "This command switches branches or restores working tree files.",
      },
      {
        name: "git switch",
        tooltip: "This command switches branches.",
      },
      {
        name: "git merge",
        tooltip: "This command merges two branches together.",
      },
      {
        name: "git mergetool",
        tooltip: "This command is a graphical merge tool.",
      },
      {
        name: "git log",
        tooltip: "This command shows the commit logs.",
      },
      {
        name: "git stash",
        tooltip: "This command temporarily shelves changes.",
      },
      {
        name: "git tag",
        tooltip: "This command tags a specific commit with a simple, human-readable handle that never moves.",
      },
      {
        name: "git worktree",
        tooltip: "This command manages multiple working trees.",
      },
    ],
  },
  {
    name: "Sharing and Updating Projects",
    commands: [
      {
        name: "git fetch",
        tooltip: "This command downloads objects and references from another repository.",
      },
      {
        name: "git pull",
        tooltip: "This command fetches from and integrates with another repository or a local branch.",
      },
      {
        name: "git push",
        tooltip: "This command updates remote references along with associated objects.",
      },
      {
        name: "git remote",
        tooltip: "This command manages tracked repositories.",
      },
      {
        name: "git submodule",
        tooltip: "This command initializes, updates, or inspects submodules.",
      },
    ],
  },
  {
    name: "Inspection and Comparison",
    commands: [
      {
        name: "git show",
        tooltip: "This command shows various types of objects.",
      },
      {
        name: "git log",
        tooltip: "This command shows the commit logs.",
      },
      {
        name: "git diff",
        tooltip: "This command shows changes between commits, commit and working tree, etc.",
      },
      {
        name: "git shortlog",
        tooltip: "This command summarizes git log output.",
      },
      {
        name: "git describe",
        tooltip: "This command gives the most recent tag that is reachable from a commit.",
      },
    ],
  },
  {
    name: "Patching",
    commands: [
      {
        name: "git apply",
        tooltip: "This command applies a patch to files and/or to the index.",
      },
      {
        name: "git cherry-pick",
        tooltip: "This command applies changes introduced by some existing commits.",
      },
      {
        name: "git diff",
        tooltip: "This command generates patch files.",
      },
      {
        name: "git rebase",
        tooltip: "This command applies commits in another branch on the top of the current branch.",
      },
      {
        name: "git revert",
        tooltip: "This command reverts some existing commits.",
      },
    ],
  },
  {
    name: "Debugging",
    commands: [
      {
        name: "git bisect",
        tooltip: "This command uses binary search to find the commit that introduced a bug.",
      },
      {
        name: "git blame",
        tooltip: "This command shows what revision and author last modified each line of a file.",
      },
      {
        name: "git grep",
        tooltip: "This command prints lines matching a pattern.",
      },
    ],
  },
  {
    name: "Email",
    commands: [
      {
        name: "git am",
        tooltip: "This command applies patches from emails.",
      },
      {
        name: "git apply",
        tooltip: "This command applies a patch to files and/or to the index.",
      },
      {
        name: "git format-patch",
        tooltip: "This command prepares patches for e-mail submission.",
      },
      {
        name: "git send-email",
        tooltip: "This command sends a collection of patches as emails.",
      },
    ],
  },
  {
    name: "External Systems",
    commands: [
      {
        name: "git svn",
        tooltip: "This command bidirectionally bridges a Subversion repository.",
      },
      {
        name: "git fast-import",
        tooltip: "This command Backend for fast-import.",
      },
      {
        name: "git fast-export",
        tooltip: "This command Backend for fast-export.",
      },
    ],
  },
  {
    name: "Administration",
    commands: [
      {
        name: "git clean",
        tooltip: "This command removes untracked files from the working tree.",
      },
      {
        name: "git gc",
        tooltip: "This command cleans up unnecessary files and optimizes the local repository.",
      },
      {
        name: "git repack",
        tooltip: "This command packs unpacked objects in a repository.",
      },
      {
        name: "git prune",
        tooltip: "This command removes objects that are no longer pointed to by any object in the database.",
      },
      {
        name: "git fsck",
        tooltip: "This command performs an integrity check of the Git file system.",
      },
      {
        name: "git reflog",
        tooltip: "This command manages reflog information.",
      },
    ],
  },
  {
    name: "Server Admin",
    commands: [
      {
        name: "git daemon",
        tooltip: "This command A really simple server for Git repositories.",
      },
      {
        name: "git update-server-info",
        tooltip: "This command Updates auxiliary info file to help dumb servers.",
      },
    ],
  },
  {
    name: "Plumbing Commands",
    commands: [
      {
        name: "git cat-file",
        tooltip: "This command provides content or type and size information for repository objects.",
      },
      {
        name: "git commit-tree",
        tooltip: "This command Creates a new commit object.",
      },
      {
        name: "git count-objects",
        tooltip: "This command Counts unpacked number of objects and their disk consumption.",
      },
    ],
  },
]
