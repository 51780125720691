import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { SheetLayout } from "./components/sheetLayout";
import { GitSheet } from "./sheets/github";
import { GithubSheet } from "./sheets/gh";
import { ColorSheet } from "./sheets/color";
import { NpmSheet } from "./sheets/npm";
import { BunSheet } from "./sheets/bun";
import { SqlSheet } from "./sheets/sql";


function App() {
  const [activeTab, setActiveTab] = useState("git");
  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };

  const tabs = [
    {
      name: "git",
      icon: "󰆏",
    },
    {
      name: "gh",
      icon: "󰆏",
    },
    {
      name: "color",
      icon: "󰆏",
    },
    {
      name: "npm",
      icon: "󰆏",
    },
    {
      name: "bun",
      icon: "󰆏",
    },
    {
      name: "sql",
      icon: "󰆏",
    },
  ];

  const currentSheet = () => {
    switch (activeTab) {
      case "git":
        return GitSheet;
      case "gh":
        return GithubSheet;
      case "color":
        return ColorSheet;
      case "npm":
        return NpmSheet;
      case "bun":
        return BunSheet;
      case "sql":
        return SqlSheet;
      // Create new case for new tab
      default:
        return GithubSheet;
    }
  };

  return (
    <div className="App">
      <div className="nav-wrapper position-relative mb-4 d-flex justify-content-center">
        <ul
          className="nav nav-pills nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          role="tablist"
        >
          {tabs.map((tab, index) => {
            return (
              <li className="nav-item" key={`${tab}_${index}`}>
                <div
                  className={
                    tab.name == activeTab
                      ? "nav-link nav-link-active mb-sm-3 mb-md-0 active tabButton"
                      : "nav-link mb-sm-3 mb-md-0 tabButton"
                  }
                  style={{ cursor: "pointer" }}
                  id={`${tab}_${index}`}
                  onClick={() => handleTabChange(tab.name)}
                >
                  <i className="nf nf-md-content_copy"></i> {tab.name}
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <SheetLayout sheets={currentSheet()} />
    </div>
  );
}

export default App;
