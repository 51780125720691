export const Button = ({ color, name }: { color: string; name: string }) => {
  const copyCommand = (command: string) => {
    navigator.clipboard.writeText(command);
  };

  return (
    <div
      className="btn btn-primary mr-4 mb-4 tabButton"
      style={{
        color: color ? "white" : "",
        backgroundColor: color ? name : "",
        textShadow: color ? "#000 1px 0 10px" : "",
        cursor: "pointer",
      }}
      onClick={() => copyCommand(name)}
    >
      {name}
    </div>
  );
};
