export const NpmSheet = [
  {
    name: "NPM",
    commands: [
      {
        name: "npm install",
        tooltip: "Install dependencies",
      },
      {
        name: "npm install <package>",
        tooltip: "Install a package",
      },
      {
        name: "npm install <package> --save",
        tooltip: "Install and save to dependencies",
      },
      {
        name: "npm install <package> --save-dev",
        tooltip: "Install and save to devDependencies",
      },
      {
        name: "npm install <package> -g",
        tooltip: "Install globally",
      },
      {
        name: "npm install <package>@<version>",
        tooltip: "Install a specific version",
      },
      {
        name: "npm install <package>@latest",
        tooltip: "Install the latest version",
      },
      {
        name: "npm uninstall <package>",
        tooltip: "Uninstall a package",
      },
      {
        name: "npm update",
        tooltip: "Update dependencies",
      },
      {
        name: "npm update <package>",
        tooltip: "Update a package",
      },
      {
        name: "npm update <package> -g",
        tooltip: "Update globally",
      },
      {
        name: "npm list",
        tooltip: "List installed packages",
      },
      {
        name: "npm list --depth=0",
        tooltip: "List top-level packages",
      },
      {
        name: "npm list --depth=0 -g",
        tooltip: "List globally installed packages",
      },
      {
        name: "npm search <package>",
        tooltip: "Search for a package",
      },
      {
        name: "npm init",
        tooltip: "Initialize a new project",
      },
      {
        name: "npm start",
        tooltip: "Start the project",
      }
    ],
  },
];
