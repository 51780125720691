export const GithubSheet = [
  {
    name: "GitHub (gh)",
    commands: [
      {
        name: "gh auth",
        tooltip: "Authenticate with GitHub",
      },
      {
        name: "gh gist",
        tooltip: "Create a gist",
      },
      {
        name: "gh codespace",
        tooltip: "Manage codespaces",
      },
      {
        name: "gh issue",
        tooltip: "Manage issues",
      },
      {
        name: "gh pr",
        tooltip: "Manage pull requests",
      },
      {
        name: "gh repo",
        tooltip: "Manage repositories",
      },
      {
        name: "gh org",
        tooltip: "Manage organizations",
      },
      {
        name: "gh project",
        tooltip: "Manage projects",
      },
      {
        name: "gh run",
        tooltip: "Manage GitHub Actions workflows",
      },
      {
        name: "gh secret",
        tooltip: "Manage repository secrets",
      },
      {
        name: "gh release",
        tooltip: "Manage releases",
      },
      {
        name: "gh api",
        tooltip: "Make an authenticated GitHub API request",
      },
      {
        name: "gh config",
        tooltip: "Manage configuration for gh",
      },
      {
        name: "gh completion",
        tooltip: "Generate shell completion scripts",
      },
      {
        name: "gh extension",
        tooltip: "Add or remove extensions",
      },
      {
        name: "gh help",
        tooltip: "Help about any command",
      },
      {
        name: "gh version",
        tooltip: "Show gh version",
      },
    ],
  },
];
