import { Tooltip } from "../components/toolTip";

export const BunSheet = [
  {
    name: "Bun Run",
    commands: [
      {
        name: "bun run start",
        tooltip: "This command starts the Bun server.",
      },
      {
        name: "bun run build",
        tooltip: "This command builds the Bun project.",
      },
      {
        name: "bun run test",
        tooltip: "This command runs the tests in the Bun project.",
      },
      {
        name: "bun run lint",
        tooltip: "This command runs the linter in the Bun project.",
      },
      {
        name: "bun run format",
        tooltip: "This command formats the code in the Bun project.",
      },
      {
        name: "bun run deploy",
        tooltip: "This command deploys the Bun project.",
      }, 
    ],
  },
  {
    name: "Bun Server",
    commands: [
      {
        name: "bun server start",
        tooltip: "This command starts the Bun server.",
      },
      {
        name: "bun server stop",
        tooltip: "This command stops the Bun server.",
      },
      {
        name: "bun server restart",
        tooltip: "This command restarts the Bun server.",
      },
      {
        name: "bun server status",
        tooltip: "This command checks the status of the Bun server.",
      },
      {
        name: "bun server logs",
        tooltip: "This command checks the logs of the Bun server.",
      },
      {
        name: "bun server config",
        tooltip: "This command checks the configuration of the Bun server.",
      },
    ],
  },
  {
    name: "Bun Project",
    commands: [
      {
        name: "bun project create",
        tooltip: "This command creates a new Bun project.",
      },
      {
        name: "bun project delete",
        tooltip: "This command deletes a Bun project.",
      },
      {
        name: "bun project list",
        tooltip: "This command lists all Bun projects.",
      },
      {
        name: "bun project info",
        tooltip: "This command shows information about a Bun project.",
      },
      {
        name: "bun project update",
        tooltip: "This command updates a Bun project.",
      },
      {
        name: "bun project clone",
        tooltip: "This command clones a Bun project.",
      },
    ],
  },
  {
    name: "Bun User",
    commands: [
      {
        name: "bun user create",
        tooltip: "This command creates a new Bun user.",
      },
      {
        name: "bun user delete",
        tooltip: "This command deletes a Bun user.",
      },
      {
        name: "bun user list",
        tooltip: "This command lists all Bun users.",
      },
      {
        name: "bun user info",
        tooltip: "This command shows information about a Bun user.",
      },
      {
        name: "bun user update",
        tooltip: "This command updates a Bun user.",
      },
    ],
  },
  {
        name: "Bun Add",
        commands: [
          {
            name: "bun add user",
            tooltip: "This command adds a user to the Bun project.",
          },
          {
            name: "bun add project",
            tooltip: "This command adds a project to the Bun user.",
          },
          {
            name: "bun add role",
            tooltip: "This command adds a role to the Bun user.",
          },
          {
            name: "bun add permission",
            tooltip: "This command adds a permission to the Bun user.",
          },
        ],

  },    
];