import { Button } from "./button";
import { Tooltip } from "./toolTip";

export const SheetLayout = ({ sheets }: { sheets?: any }) => {
  return (
    <div className="container bg-primary border-light p-4 rounded">
      {sheets?.map((sheet: any, index: number) => {
        return (
          <div className="layout position-relative" key={index}>
            <div className="container shadow-inset bg-primary border-light p-4 rounded">
              <h1 className="display-4 h1-center pb-sm">{sheet.name}</h1>
              {sheet?.commands?.map((command: any, index: number) => {
                return (
                  <Tooltip key={index} text={command.tooltip}>
                    <Button
                      key={index}
                      color={command.color}
                      name={command.name}
                    />
                  </Tooltip>
                );
              })}
            </div>
            <hr />
          </div>
        );
      })}
    </div>
  );
};
